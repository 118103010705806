import * as types from '../mutation-types'
import _ from 'lodash'

// initial state
const state = {
	nomenclatures: {}
}

// getters
const getters = {
	nomenclatureExist: (state) => (nomenclature) => {
		return state.nomenclatures[nomenclature] && state.nomenclatures[nomenclature]['list'];
	},
	getNomenclatureList: (state, getters) => (nomenclature, langue) => {
		let list = [];
		if(state.nomenclatures[nomenclature] && state.nomenclatures[nomenclature]['list']){
			_.forEach(state.nomenclatures[nomenclature]['list'], function(fields, code){
				if(fields['name']){
					list.push({val: code, name: getters.getI18n(fields['name'], langue)});
				}else{
					list.push({val: code, name: 'no entry name found'});
				}
			})
		}
		return list;
	},
	getNomenclatureList_ByType: (state, getters, rootStore) => (type, type_value, langue) => { //AS-->EX: type : caracteristique / type_value : 33 / langue : 'eng'
		if(!langue) langue = rootStore.ui.langue;
		let _nomenclature = false;
		_.forEach(state.nomenclatures, function(nomenclature, code){
			if(nomenclature[type] && nomenclature[type].includes(type_value)){
				_nomenclature = getters.getNomenclatureList(code, langue);
			}
		})
		return _nomenclature;
	},
	getNomenclatureValue: (state, getters, rootStore) => (type, type_value, value, langue) => { //AS-->EX: type : caracteristique / type_value : 33 / value : 'Bleu' / langue : 'eng'
		let _value = value;
		if(!langue) langue = rootStore.ui.langue;

		_.forEach(state.nomenclatures, function(nomenclature){
			if(nomenclature[type] && nomenclature[type].includes(type_value) && nomenclature['list'] && nomenclature['list'][value] && nomenclature['list'][value]['name']){
				_value = getters.getI18n(nomenclature['list'][value]['name'], langue);
			}
		})

		return _value;
	},
	getNomenclatureList_ByType_allLanguages: (state) => (type, type_value) => { //AS-->EX: type : caracteristics / type_value : 33
		let _nomenclature = false;
		_.forEach(state.nomenclatures, function(nomenclature, code){
			if(nomenclature[type] && nomenclature[type].includes(type_value)){
				_nomenclature = _.cloneDeep(nomenclature.list)
			}
		})
		return _nomenclature;
	},
	getI18n: (state) => (trads, langue) => {
		let trad = '';
		if(typeof trads == 'object'){
			//AS--> Si la traduction dans la langue demandée existe
			if(trads[langue] != undefined){
				trad = trads[langue];

			//AS--> Sinon, renvoie la première trad trouvée
			}else{
				_.forEach(trads, function(val, lang){
					if(!trad) trad = val;
				})
			}
		}
		return trad;
	}
}

// actions
const actions = {
	
}

// mutations
const mutations = {
	[types.NOMENCLATURE_SET_NOMENCLATURES](state, nomenclatures) {
		console.time('NOMENCLATURE_SET_NOMENCLATURES');
		state.nomenclatures = nomenclatures;
		console.timeEnd('NOMENCLATURE_SET_NOMENCLATURES');
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}